import axiosExternalClient from "core/axiosExternalClient";
import axios from "axios";
import queryString from "query-string";
import TokenService from "utils/TokenService";

const axiosExClient = axios.create({
  baseURL: "https://public-v1-stg.omicall.com",
  headers: {
    Authorization: `Bearer ${TokenService.getTokenCall()}`,
    "Content-Type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});
const exApi = {
  setTokenCall: () => {
    const url = "/api/auth";
    const params = { apiKey: process.env.REACT_APP_EXTERNAL_APIKEY };
    return axiosExternalClient.get(
      url,
      { params },
      {
        ignoreInterceptor: true,
      }
    );
  },

  refreshToken: (token) => {
    const url = "/api/auth/refreshToken";
    return axiosExternalClient.post(url, token, { ignoreInterceptor: true });
  },

  getUser: () => {
    const url = "/api/call_center/internal_phone/list";
    return axiosExternalClient.get(url);
  },

  // getCallHistoryList: (params) => {
  //   const url = "/api/call_transaction/list";
  //   return axiosExternalClient.get(url, params);
  // },

  getCallHistoryList: async (params, retryCount = 1) => {
    let attempts = 0;

    while (attempts < retryCount) {
      try {
        const response = await axiosExClient.get("/api/call_transaction/list", {
          params: params,
        });
        return response.data.payload.items;
      } catch (error) {
        axios
          .get("https://public-v1-stg.omicall.com/api/auth", {
            params: {
              apiKey: "E6451964B89D9636AF12C06088D676A43818EEFECD08CC93126D185B042613FB",
            },
          })
          .then((response) => {
            if (response.data.payload.access_token) {
              TokenService.updateTokenCall(response.data.payload.access_token);
            }
          })
          .catch((error) => {
            console.error("Error:", error.response ? error.response.data : error.message);
          });
        attempts++;
        if (attempts >= retryCount) {
          throw error; // Re-throw the error if maximum attempts are reached
        }
      }
    }
  },
};
export default exApi;
