import React, { useEffect, useState } from "react";
import { Button, Form, Switch, Radio, message } from "antd";
import { SelectCustom, DatePickerCustom, InputCustom, InputNumberCustom } from "components";
import { RetweetOutlined, RightOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import khungGioApi from "api/Call/khungGioApi";
import tripPlanApi from "api/Call/tripPlanApi";
import tripRecordApi from "api/Call/tripRecord";
import moment from "moment";
import "moment/locale/vi";

moment.locale("vi");

function TripPopupControl({
  dataCus,
  setTripOrderFunc,
  setTuyenIdFunc,
  setTripOrderSaveFunc,
  setLoadingFunc,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [listKhungGio, setListKhungGio] = useState([]);
  const [tuyenId, setTuyenId] = useState("TKDN");
  const [driverPlan, setDriverPlan] = useState([]);
  const [driverOrders, setDriverOrders] = useState([]);
  const [date, setDate] = useState(
    moment().isAfter(moment(moment().format("DD/MM/YYYY") + "19:30", "DD/MM/YYYYHH:mm"))
      ? moment().add(1, "d").format("DD/MM/YYYY")
      : moment().format("DD/MM/YYYY")
  );
  const [listOrder, setListOrder] = useState([]);

  const toggleTuyen = () => {
    if (tuyenId === "TKDN") {
      form.setFieldsValue({
        tuyen: "DNTK",
      });
      setTuyenId("DNTK");
      onChangeForm(date, "DNTK");
      // getKhungGio("DNTK", date);
    } else {
      form.setFieldsValue({
        tuyen: "TKDN",
      });
      setTuyenId("TKDN");
      onChangeForm(date, "TKDN");
    }
  };

  // useEffect(() => {
  //   form.setFieldsValue({
  //     ngayDi: moment(date, "DD/MM/YYYY"),
  //     tuyen: "TKDN",
  //     soGhe: 1,
  //     tienFlg: false,
  //   });
  //   getDriverPlan(moment().format("DD/MM/YYYY"));
  // }, []);

  useEffect(() => {
    if (dataCus) {
      if (dataCus.status === "COPY") {
        form.setFieldsValue({
          name: dataCus.name,
          tuyen: dataCus.tuyen,
          ngayDi: moment(date, "DD/MM/YYYY"),
          soGhe:
            dataCus.tripType === 1
              ? 100
              : dataCus.tripType === 2
              ? 101
              : dataCus.tripType === 3
              ? 102
              : dataCus.soKhach,
          giaMoi: dataCus.giaMoi === 0 ? dataCus.soTien : "",
          tienFlg: dataCus.tienFlg === 0,
          ghiChu: dataCus.ghiChu,
          khungGioId: dataCus.khungGioId,
        });
        setTuyenId(dataCus.tuyen);
        onChangeForm(date, dataCus.tuyen, dataCus.khungGioId);
      } else if (dataCus.status === "EDIT") {
        form.setFieldsValue({
          name: dataCus.name,
          tuyen: dataCus.tuyen,
          ngayDi: moment(dataCus.ngayDi, "DD/MM/YYYY"),
          soGhe:
            dataCus.tripType === 1
              ? 100
              : dataCus.tripType === 2
              ? 101
              : dataCus.tripType === 3
              ? 102
              : dataCus.soKhach,
          giaMoi: dataCus.giaMoi === 0 ? dataCus.soTien : "",
          tienFlg: dataCus.tienFlg === 0,
          ghiChu: dataCus.ghiChu,
          khungGioId: dataCus.khungGioId,
        });
        setTuyenId(dataCus.tuyen);
        onChangeForm(dataCus.ngayDi, dataCus.tuyen, dataCus.khungGioId);
      } else if (dataCus.status === "NEW") {
        form.setFieldsValue({
          name: dataCus.name,
          ngayDi: moment(date, "DD/MM/YYYY"),
          tuyen: "TKDN",
          soGhe: 1,
          tienFlg: false,
        });
        setTuyenId("TKDN");
        getDriverPlan(date);
      }
    }
  }, [dataCus]);

  const bindingData = () => {
    const params = form.getFieldValue();
    const data = {
      ...params,
      ngayDi: params.ngayDi ? moment(params.ngayDi).format("DD/MM/YYYY") : undefined,
    };
    setTripOrderSaveFunc(data);
  };

  useEffect(() => {
    bindingData();
  }, [form.getFieldValue()]);

  const getDriverPlan = async (param) => {
    setLoadingFunc(true);
    const resultDriverPlan = await tripPlanApi.getDriverPlan({ ngayPlan: param });
    const resultKhungGio = await khungGioApi.getKhungGio({
      ngayPlan: param,
      tuyen: tuyenId,
    });

    if (resultKhungGio.success && resultDriverPlan.success) {
      setDriverPlan(resultDriverPlan.data);
      if (resultKhungGio.data.length > 0) {
        const ls = resultKhungGio.data.map((e) => {
          e.soKhachChuyen = 0;
          resultDriverPlan.data.map((o) => {
            if (o.khungGioId === e.khungGioId) {
              e.soKhachChuyen = e.soKhachChuyen + o.carType;
            }
          });
          if (
            moment()
              .add(-90, "minutes")
              .isAfter(moment(date + e.khungGioName, "DD/MM/YYYYHH:mm"))
          ) {
            if (e.soKhach == 0 && e.soKhachChuyen == 0) {
              e.color = "text-gray-400 ";
            } else if (e.soKhach > e.soKhachChuyen) {
              e.color = "text-red-300 ";
            } else if (e.soKhach < e.soKhachChuyen) {
              e.color = "text-blue-300 ";
            } else {
              e.color = "text-green-300 ";
            }
          } else {
            if (e.soKhach == 0 && e.soKhachChuyen == 0) {
              e.color = "";
            } else if (e.soKhach > e.soKhachChuyen) {
              e.color = "text-red-500 ";
            } else if (e.soKhach < e.soKhachChuyen) {
              e.color = "text-blue-500 ";
            } else {
              e.color = "text-green-500 ";
            }
          }
          return e;
        });
        setLoadingFunc(false);
        setListKhungGio(ls);
      }
      setTuyenIdFunc(tuyenId);
    } else {
      message.error("Lấy khung giờ lỗi");
    }
  };

  const onChangeForm = async (ngay, tuyen, khungGio) => {
    setLoadingFunc(true);
    const resultDriverPlan = await tripPlanApi.getDriverPlan({ ngayPlan: ngay });
    const resultKhungGio = await khungGioApi.getKhungGio({
      ngayPlan: ngay,
      tuyen: tuyen,
    });

    if (resultKhungGio.success && resultDriverPlan.success) {
      setDriverPlan(resultDriverPlan.data);
      if (resultKhungGio.data.length > 0) {
        const ls = resultKhungGio.data.map((e) => {
          e.soKhachChuyen = 0;
          resultDriverPlan.data.map((o) => {
            if (o.khungGioId === e.khungGioId) {
              e.soKhachChuyen = e.soKhachChuyen + o.carType;
            }
          });
          if (
            moment()
              .add(-90, "minutes")
              .isAfter(moment(ngay + e.khungGioName, "DD/MM/YYYYHH:mm"))
          ) {
            if (e.soKhach == 0 && e.soKhachChuyen == 0) {
              e.color = "text-gray-400 ";
            } else if (e.soKhach > e.soKhachChuyen) {
              e.color = "text-red-300 ";
            } else if (e.soKhach < e.soKhachChuyen) {
              e.color = "text-blue-300 ";
            } else {
              e.color = "text-green-300 ";
            }
          } else {
            if (e.soKhach == 0 && e.soKhachChuyen == 0) {
              e.color = "";
            } else if (e.soKhach > e.soKhachChuyen) {
              e.color = "text-red-500 ";
            } else if (e.soKhach < e.soKhachChuyen) {
              e.color = "text-blue-500 ";
            } else {
              e.color = "text-green-500 ";
            }
          }
          return e;
        });
        setLoadingFunc(false);
        setListKhungGio(ls);
      }
      const dataDriver = resultDriverPlan.data.filter((item) => {
        return item.khungGioId == khungGio;
      });
      if (khungGio) {
        form.setFieldsValue({
          khungGioId: khungGio,
        });

        const result = await tripRecordApi.getTripOrders({
          tripStatus: "Y",
          ngayPlan: ngay,
          khungGioId: khungGio,
        });
        setTripOrderFunc(result.data, dataDriver);
        setDriverOrders(dataDriver);
      } else {
        setDriverOrders([]);
        setTripOrderFunc([], []);
        form.setFieldsValue({
          khungGioId: null,
        });
      }
    } else {
      message.error("Lấy khung giờ lỗi");
    }
    setTuyenIdFunc(tuyen);
  };

  const handleChangeKhungGio = async (value) => {
    if (value) {
      const dataDriver = await driverPlan.filter((item) => {
        return item.khungGioId == value;
      });
      const result = await tripRecordApi.getTripOrders({
        tripStatus: "Y",
        ngayPlan: date,
        khungGioId: value,
      });
      const ls = result.data.filter((obj) => {
        return (obj.khungGioId = value);
      });
      setListOrder(ls);
      setDriverOrders(dataDriver);
      setTripOrderFunc(result.data, dataDriver);
    }
  };

  const handleChangeDate = (value) => {
    setDate(moment(value).format("DD/MM/YYYY"));
    onChangeForm(moment(value).format("DD/MM/YYYY"), tuyenId);
  };

  const handleNext = () => {
    form.setFieldsValue({
      ngayDi: moment(date, "DD/MM/YYYY").add(1, "d"),
    });
    setDate(moment(moment(date, "DD/MM/YYYY").add(1, "d")).format("DD/MM/YYYY"));
    onChangeForm(moment(moment(date, "DD/MM/YYYY").add(1, "d")).format("DD/MM/YYYY"), tuyenId);
  };

  // const handleClosePopup = () => {
  //   setShowPopup(false);
  // };

  // const onSave = () => {
  //   const params = form.getFieldValue();
  //   const data = {
  //     ...params,
  //     ngayDi: params.ngayDi ? moment(params.ngayDi).format("DD/MM/YYYY") : undefined,
  //     diemDonAdd: tripInfor.diemDonAdd,
  //     diemDonLat: tripInfor.diemDonLat,
  //     diemTraAdd: tripInfor.diemTraAdd,
  //     diemDonLng: tripInfor.diemDonLng,
  //     diemTraLat: tripInfor.diemTraLat,
  //     diemTraLng: tripInfor.diemTraLng,
  //     cusId: cusInfor.phoneNumber,
  //   };
  //   tripRecordApi.saveTripRecord(data);
  // };

  return (
    <div className="left-box-map">
      {contextHolder}
      <Form
        form={form}
        name="menu-detail"
        colon={false}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        // onFinish={onSave}
        // onChange={onChangeAll}
      >
        <div className="form-detail">
          <div className="">
            <div className="flex">
              <Form.Item onChange={bindingData} name="name">
                <InputCustom label="Tên" className="w-36"></InputCustom>
              </Form.Item>
              <Button className="!rounded-md !ml-2" size="large" icon={<RetweetOutlined />} />
            </div>
            <div className="flex">
              <Form.Item name="tuyen">
                <SelectCustom
                  onChange={(value) => toggleTuyen()}
                  allowClear
                  options={[
                    { value: "TKDN", label: "Tam Kỳ - Đà Nẵng" },
                    { value: "DNTK", label: "Đà Nẵng - Tam Kỳ" },
                  ]}
                  label="Tuyến"
                  className="w-36"
                  optionFilterProp="children"
                ></SelectCustom>
              </Form.Item>
              <Button
                className="!rounded-md ml-2"
                size="large"
                icon={<RetweetOutlined />}
                onClick={toggleTuyen}
              />
            </div>
            <div className="flex">
              <Form.Item
                name="ngayDi"
                rules={[
                  {
                    required: true,
                    message: t("UI_ADM_006.menuDetail.engNmReq"),
                  },
                ]}
                onChange={bindingData}
              >
                <DatePickerCustom
                  style={{ width: "280px" }}
                  label={t("Ngày đi")}
                  format="DD/MM(dddd)"
                  placeholder=""
                  onChange={(value) => handleChangeDate(value)}
                />
              </Form.Item>
              <Button
                className="!rounded-md ml-8"
                onClick={() => {
                  handleNext();
                }}
                size="large"
                icon={<RightOutlined />}
              />
            </div>
            <div className="flex space-x-4">
              <Form.Item name="soGhe" className="w-32">
                <SelectCustom
                  // onClick={handleClickLocNo}
                  // allowClear
                  onSelect={bindingData}
                  options={[
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3" },
                    { value: 4, label: "4" },
                    { value: 5, label: "5" },
                    { value: 6, label: "6" },
                    { value: 100, label: "Hàng" },
                    { value: 102, label: "Bao xe 4 chổ" },
                    { value: 101, label: "Bao xe 7 chổ" },
                  ]}
                  label="Số Ghế"
                  style={{ width: "122px" }}
                ></SelectCustom>
              </Form.Item>
              <Form.Item onChange={bindingData} name="giaMoi" className="w-36 mr-2">
                <InputNumberCustom
                  min={0}
                  max={10000}
                  label="Giá mới"
                  style={{ width: "154px" }}
                ></InputNumberCustom>
              </Form.Item>
              <Form.Item name="tienFlg" className="w-4 !ml-4">
                {/* <Tooltip title="Đã Thanh Toán"> */}
                <Switch
                  onClick={() => {
                    bindingData();
                  }}
                  // className="!pt-2"
                  // style={{ backgroundColor: "orange ", height: "2px" }}
                  // size="small"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
                {/* </Tooltip> */}
              </Form.Item>
            </div>
            <div className="flex">
              <Form.Item onChange={bindingData} name="ghiChu">
                <InputCustom label="Ghi chú" style={{ width: "280px" }}></InputCustom>
              </Form.Item>
              <Form.Item onChange={bindingData} name="ghiChuFlg" className="w-4 !ml-2">
                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
              </Form.Item>
            </div>
          </div>
          <div className="mt-2 border-slate-400 border-b-2">
            <Form.Item
              name="khungGioId"
              rules={[
                {
                  required: true,
                  message: t("UI_ADM_006.menuDetail.typeMnReq"),
                },
              ]}
            >
              <Radio.Group
                className="w-96"
                onChange={(e) => {
                  handleChangeKhungGio(e.target.value);
                }}
              >
                {listKhungGio.map((khunggio, index) => {
                  return (
                    <Radio
                      className={
                        index === 0 ||
                        index === 1 ||
                        index === 4 ||
                        index === 5 ||
                        index === 8 ||
                        index === 9 ||
                        index === 12 ||
                        index === 13 ||
                        index === 16 ||
                        index === 17 ||
                        index === 20 ||
                        index === 21 ||
                        index === 24 ||
                        index === 25 ||
                        index === 28
                          ? "bg-slate-200 w-40 !pl-2 !m-0 !pt-2 !pb-0 !pb-0"
                          : "w-40 !pl-2 !mr-0 !pt-2"
                      }
                      key={khunggio.khungGioId}
                      value={khunggio.khungGioId}
                    >
                      <p className={khunggio.color + "text-base font-bold mb-1"}>
                        {khunggio.khungGioName} - {khunggio.soKhach}/{khunggio.soKhachChuyen}
                      </p>
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="pt-2 w-48">
            {driverOrders.map((item, index) => {
              return (
                <Button key={index} className="w-25 !ml-0 !mb-3 ! pl-0" type="text">
                  <div className="flex">
                    <p
                      className={
                        index === 0
                          ? "bg-[#260707] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                          : index === 1
                          ? "bg-[#181414] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                          : index === 2
                          ? "bg-[#1b2dd3] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                          : index === 3
                          ? "bg-[#0de053] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                          : index === 4
                          ? "bg-[#fd7100] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                          : index === 5
                          ? "bg-[#567328] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                          : index === 6
                          ? "bg-[#5cb5b6] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                          : index === 7
                          ? "bg-[#6880e8] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                          : index === 8
                          ? "bg-[#f16787] p-1.5 rounded-full text-white w-8 h-8 text-center text-sm font-bold !mb-0"
                          : "" +
                            "bg-gray-40 p-1.5 rounded-full w-8 h-8 text-center text-sm font-bold !mb-0"
                      }
                    >
                      {item.soDriverKhach}
                    </p>
                    <p className="text-center ml-1 p-1 rounded-full h-8 text-base font-medium !mb-0">
                      {item.driverName}
                    </p>
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
      </Form>
    </div>
  );
}
export default TripPopupControl;
