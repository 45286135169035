import React, { useEffect, useState } from "react";
import { Col, Button, message, Tooltip, Modal } from "antd";
import Point from "ol/geom/Point.js";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Vector as VectorSource } from "ol/source.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import Overlay from "ol/Overlay";
import { transform } from "ol/proj.js";
import OSM from "ol/source/OSM";
import Feature from "ol/Feature.js";
import { Icon, Style } from "ol/style";
import { useDispatch } from "react-redux";
import TripHistoryPopup from "pages/TripHistoryPopup/TripHistoryPopup";
import TripMapPopup from "pages/TripMapPopup/TripMapPopup";
import tripRecordApi from "api/Call/tripRecord";
import CallHistoryPopup from "pages/CallHistoryPopup/CallHistoryPopup";
import moment from "moment";
import "moment/locale/vi";
import TokenService from "utils/TokenService";
import Fill from "ol/style/Fill";
import Text from "ol/style/Text";
import { defaults as defaultInteractions, Modify } from "ol/interaction";
import { fromLonLat } from "ol/proj";

const { confirm } = Modal;

const admin = TokenService.getUserLocalStorage()?.coCd == "CLV";

moment.locale("vi");
import { CopyOutlined, PhoneOutlined, SoundOutlined, FileSearchOutlined } from "@ant-design/icons";
import m01 from "./01-260707.png";
import m02 from "./02-da1313.png";
import m03 from "./03-1b2dd3.png";
import m04 from "./04-0de053.png";
import m05 from "./20-fd7100.png";
import m06 from "./21-567328.png";
import m07 from "./22-5cb5b6.png";
import m08 from "./23-6880e8.png";
import m09 from "./24-f16787.png";
import m00 from "./05-d9e00d.png";
import m10 from "./10-e71b85.png";
import m11 from "./11-e71b85.png";
import ThayDoiTaiXePopup from "./popup/ThayDoiTaiXePopup";

let selectMarkerList = [];

function MapDetail({
  tripOrder,
  addFlg,
  ghiChuFlg,
  gheFlg,
  driverList,
  khungGioList,
  setReloadFlgFunc,
}) {
  const dispatch = useDispatch();

  const [markers, setMarkers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const [selectMarker, setSelectMarker] = useState(false);

  const [textSelectMarker, setTextSelectMarker] = useState("");
  const [dataPopup, setDataPopup] = useState([]);
  const [centerLoc, setCenterLoc] = useState({
    lat: 16.047476382466968,
    lng: 108.19890504473366,
  });
  const [popupId, setPopupId] = useState();
  const [messageApi, contextHolder] = message.useMessage();

  const [historyPopupData, setHistoryPopupData] = useState({});
  const [isOpenHistory, setIsOpenHistory] = useState(false);

  const [tripMapPopupData, setTripMapPopupData] = useState(false);
  const [isOpenTripMapPopup, setIsOpenTripMapPopup] = useState(false);

  const [callHistoryPopupData, setCallHistoryPopupData] = useState(false);
  const [isOpenCallHistoryPopup, setIsOpenCallHistoryPopup] = useState(false);
  const [cusId, setCusId] = useState("");
  const [marker, setMarker] = useState();

  const [map, setMap] = useState(null);
  const [overlay, setOverlay] = useState(null);

  // Hàm khởi tạo bản đồ
  const initializeMap = () => {
    const osmLayer = new TileLayer({
      preload: Infinity,
      source: new OSM(),
    });

    const vectorSource = new VectorSource();
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    const mapInstance = new Map({
      target: "map",
      layers: [osmLayer, vectorLayer],
      view: new View({
        center: transform([centerLoc.lng, centerLoc.lat], "EPSG:4326", "EPSG:3857"),
        zoom: 13,
      }),
      interactions: defaultInteractions({
        doubleClickZoom: false, // Disable double-click zoom
      }),
    });

    setMap(mapInstance);
    return mapInstance;
  };

  // Hàm thêm overlay vào bản đồ
  const addOverlayToMap = (mapInstance) => {
    const container = document.getElementById("popupMarker");
    const closer = document.getElementById("popup-closer");

    const overlayInstance = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    mapInstance.addOverlay(overlayInstance);
    setOverlay(overlayInstance);

    closer.onclick = function () {
      overlayInstance.setPosition(undefined);
      closer.blur();
      return false;
    };

    return overlayInstance;
  };

  // Hàm xóa overlay khỏi bản đồ
  const removeOverlayFromMap = () => {
    if (overlay) {
      overlay.setPosition(undefined); // Ẩn overlay mà không xóa khỏi bản đồ
    }
  };

  // Hàm loại bỏ bản đồ
  const removeMap = () => {
    if (map) {
      map.setTarget(null);
      setMap(null);
    }
  };

  useEffect(() => {
    // Khởi tạo bản đồ
    const mapInstance = initializeMap();
    // Thêm overlay vào bản đồ
    const overlayInstance = addOverlayToMap(mapInstance);

    // Xử lý sự kiện click cho bản đồ
    mapInstance.on("dblclick", function (event) {
      mapInstance.forEachFeatureAtPixel(event.pixel, function (feature) {
        if (feature) {
          const coordinates = feature.getGeometry().getCoordinates();
          const info = feature.get("info");
          setMarker(info);
          overlayInstance.setPosition(coordinates);
        } else {
          overlayInstance.setPosition(undefined);
        }
      });
    });

    mapInstance.on("singleclick", function (event) {
      mapInstance.forEachFeatureAtPixel(event.pixel, function (feature) {
        if (feature) {
          const clickedMarker = feature;
          const info = feature.get("info");
          clickedMarker.setStyle(
            new Style({
              image: new Icon({
                anchor: [0.5, 1],
                src: !info.selected ? m00 : info.icon,
              }),
              text: new Text({
                text: info ? info.khungGioName.substring(0, 2) + "/" + info.soKhach : "",
                offsetY: -25,
                font: "8px Calibri,sans-serif",
                fontWeight: "bold",
                scale: 1.4,
                fill: new Fill({
                  color: "#000000",
                }),
              }),
            })
          );
          feature.set("info", { ...info, selected: !info.selected });
          onClickMarker({ ...info, selected: !info.selected });
        }
      });
    });

    return () => {
      // Xóa overlay khi component unmount
      removeOverlayFromMap();
      // Loại bỏ bản đồ khi component unmount
      removeMap();
    };
  }, []);

  const offsetDistance = 0.0001; // Adjust this distance as needed

  const offsetMarkers = (markers) => {
    const offset = (lng, lat, index) => {
      const angle = index * 45 * (Math.PI / 180); // 45 degrees increment
      const newLng = lng + offsetDistance * Math.cos(angle);
      const newLat = lat + offsetDistance * Math.sin(angle);
      return [newLng, newLat];
    };

    return markers.map((marker, index) => {
      const [newLng, newLat] = offset(marker.location.lng, marker.location.lat, index);
      return {
        ...marker,
        location: { lng: newLng, lat: newLat },
      };
    });
  };

  const addMarkers = (map1, markers) => {
    const offsetMarkersList = offsetMarkers(markers);
    const layersToRemove = map
      .getLayers()
      .getArray()
      .filter((layer) => layer instanceof VectorLayer);
    layersToRemove.forEach((layer) => map.removeLayer(layer));

    const vectorSource = new VectorSource({
      features: offsetMarkersList.map((marker) => {
        const feature = new Feature({
          geometry: new Point(fromLonLat([marker.location.lng, marker.location.lat])),
          info: marker,
        });
        feature.setStyle(
          new Style({
            image: new Icon({
              anchor: [0.5, 1],
              src: marker.icon ? marker.icon : m11,
            }),
            text: new Text({
              text: marker ? marker.khungGioName.substring(0, 2) + "/" + marker.soKhach : "",
              offsetY: -25,
              font: "8px Calibri,sans-serif",
              fontWeight: "bold",
              scale: 1.4,
              fill: new Fill({
                color: "#000000",
              }),
            }),
          })
        );
        return feature;
      }),
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    const modify = new Modify({ source: vectorSource });
    map1.addInteraction(modify);

    map1.addLayer(vectorLayer);
  };

  useEffect(() => {
    const listMarker = tripOrder.map((item) => {
      if (item.tuyen === "TKDN") {
        item.location = {
          lat: Number(item.diemTraLat),
          lng: Number(item.diemTraLng),
        };
      } else {
        item.location = {
          lat: Number(item.diemDonLat),
          lng: Number(item.diemDonLng),
        };
      }
      if (item.tripPlanId) {
        driverList.map(function (o, index) {
          if (o.tripPlanId == item.tripPlanId) {
            if (index === 0) {
              item.icon = m01;
            } else if (index === 1) {
              item.icon = m02;
            } else if (index === 2) {
              item.icon = m03;
            } else if (index === 3) {
              item.icon = m04;
            } else if (index === 4) {
              item.icon = m05;
            } else if (index === 5) {
              item.icon = m06;
            } else if (index === 6) {
              item.icon = m07;
            } else if (index === 7) {
              item.icon = m08;
            } else if (index === 8) {
              item.icon = m09;
            } else if (index === 9) {
              item.icon = m10;
            }
          }
        });
      } else {
        item.icon = m11;
      }
      item.selected = false;
      return item;
    });
    setSelectMarker(false);
    // setSelectMarkerList([]);
    selectMarkerList = [];
    setMarkers(listMarker);
    setMarker({});
    if (listMarker && map) {
      addMarkers(map, listMarker);
    }
    if (overlay && map) {
      removeOverlayFromMap();
    }
  }, [tripOrder]);
  // ádas
  const onClickMarker = (params) => {
    let lsMarkerSelect = [];
    if (params.selected) {
      lsMarkerSelect = [...selectMarkerList, params];
    } else {
      lsMarkerSelect = selectMarkerList.filter((obj) => {
        return obj.tripId != params.tripId;
      });
    }

    const soGheSelect = lsMarkerSelect.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.soKhach;
    }, 0);

    setTextSelectMarker("Đang chọn " + soGheSelect + " ghế / " + lsMarkerSelect.length + " vị trí");
    // setSelectMarkerList(lsMarkerSelect);
    selectMarkerList = lsMarkerSelect;
    setSelectMarker(lsMarkerSelect.length > 0);
  };

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleOkPopup = () => {
    clickBoChon();
    setReloadFlgFunc();
    setShowPopup(false);
  };

  const clickTaoChuyen = () => {
    setDataPopup(selectMarkerList);
    setPopupId(0);
    handleShowPopup();
  };
  const clickThayDoiChuyen = () => {
    setPopupId(1);
    setDataPopup(selectMarkerList);
    handleShowPopup();
  };
  const clickBoChon = () => {
    const newSelectList = markers.map((item) => {
      if (item.driverName) {
        driverList.map(function (o, index) {
          if (Number(o.driverId) === Number(item.driverId)) {
            if (index === 0) {
              item.icon = m01;
            } else if (index === 1) {
              item.icon = m02;
            } else if (index === 2) {
              item.icon = m03;
            } else if (index === 3) {
              item.icon = m04;
            }
          }
        });
      } else {
        item.icon = m11;
      }
      return item;
    });
    setSelectMarker(false);
    selectMarkerList = [];
    setMarkers(newSelectList);
    addMarkers(map, markers);
  };

  const onCopy = (item) => {
    let text = "";
    text =
      "\n" +
      "1. " +
      item.name +
      " - " +
      item.cusId +
      " - SL " +
      item.soKhach +
      " - " +
      `${item.diemDonAdd ? item.diemDonAdd.split(",")[0].trim() : ""}` +
      " - " +
      `${item.diemTraAdd ? item.diemTraAdd.split(",")[0].trim() : ""}` +
      " - " +
      (item.tripType === 1
        ? `${item.soTien}K `
        : item.tripType === 2
        ? `${item.soTien}K `
        : item.tripType === 3
        ? `${item.soTien}K `
        : `${item.soTien * item.soKhach}K `) +
      `${item.tienFlg === 0 ? " - Đã thanh toán " : ""}` +
      `${item.ghiChu ? item.ghiChu : ""}`;

    navigator.clipboard.writeText(text);
    messageApi.open({
      type: "success",
      content: "Sao chép thành công",
    });

    // setOpen(false);
  };
  const onCall = () => {};

  const onCallHistory = (param) => {
    setCusId(param);
    setCallHistoryPopupData({ cusId: param });
    setIsOpenCallHistoryPopup(true);
  };

  const onCancelTrip = (param) => {
    confirm({
      title: "Bạn có muốn hủy chuyến đi này",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await tripRecordApi.updateOrderStatus(param);
        setReloadFlgFunc();
      },
      onCancel() {
        return;
      },
    });
  };

  const onTripHistory = (param) => {
    setCusId(param);
    setHistoryPopupData({ cusId: param });
    setIsOpenHistory(true);
  };

  const onEditTripMapPopup = (param) => {
    setTripMapPopupData({ ...param, status: "EDIT" });
    setIsOpenTripMapPopup(true);
  };

  const onCloseHistory = () => {
    setIsOpenHistory(false);
  };

  const onCloseTripMapPopup = () => {
    setIsOpenTripMapPopup(false);
  };

  const onCloseCallHistoryPopup = () => {
    setIsOpenCallHistoryPopup(false);
  };

  const callPhoneNumber = (param) => {
    window.omiSDK.makeCall(param);
  };

  return (
    <Col className="right-box item-tabs " flex="auto">
      {contextHolder}
      <div className="relative h-full">
        <div className="wrapper"></div>
        <div className="static h-full">
          <div style={{ height: "100%", width: "100%" }} id="map" className="map-container" />
          <div id="popupMarker" className="ol-popup !w-128">
            <div className="flex justify-between mb-2">
              <div className="flex ol-popup-closer">
                <Tooltip title="Sao chép" color={"#6aa84f"} key={"6aa84f"}>
                  <Button
                    icon={<CopyOutlined style={{ color: "#6aa84f" }} />}
                    onClick={() => {
                      onCopy(marker);
                    }}
                    size="small"
                    className="!ml-3 !rounded-md"
                  />
                </Tooltip>
                <Tooltip title="Gọi" color={"#ffae42"} key={"ffae42"}>
                  <Button
                    icon={<PhoneOutlined style={{ color: "#ffae42" }} />}
                    onClick={() => {
                      callPhoneNumber(marker.cusId);
                    }}
                    size="small"
                    className="!ml-3 !rounded-md"
                  />
                </Tooltip>
                <Tooltip title="Tìm ghi âm" color={"#2986cc"} key={"2986cc"}>
                  <Button
                    icon={<SoundOutlined style={{ color: "#2986cc" }} />}
                    onClick={() => onCallHistory(marker.cusId)}
                    size="small"
                    className="!ml-3 !rounded-md"
                  />
                </Tooltip>
                <Tooltip title="Lịch sử đặt vé" color={"#47dded"} key={"47dded"}>
                  <Button
                    icon={<FileSearchOutlined style={{ color: "#47dded" }} />}
                    onClick={() => onTripHistory(marker.cusId)}
                    size="small"
                    className="!ml-3 !rounded-md"
                  />
                </Tooltip>
              </div>
              <a href="#" id="popup-closer" className="ol-popup-closer">
                X
              </a>
            </div>
            <div id="popup-content w-full mt-4">
              <div>
                <div className="flex">
                  <p className="my-1.5 pt-1 mr-1 font-medium">SĐT: </p>
                  <p className="my-1.5 pt-1 mr-12">{marker ? marker.cusId : ""}</p>
                </div>
                <div className="flex">
                  <p className="my-1.5 font-medium mr-1">Tên: </p>
                  <p className="my-1.5">{marker ? marker.name : ""}</p>
                </div>
                <div className="flex">
                  <p className="my-1.5 mr-1">
                    <span className="font-medium">Điểm đi :</span> {marker ? marker.diemDonAdd : ""}
                  </p>
                </div>
                <div className="flex">
                  <p className="my-1.5 mr-1">
                    <span className="font-medium">Điểm đến:</span> {marker ? marker.diemTraAdd : ""}
                  </p>
                </div>
                <div className="flex">
                  <p className="my-1.5 font-medium mr-1">Thời gian: </p>
                  <p className="my-1.5">
                    {marker ? marker.khungGioName : ""} ngày {marker ? marker.ngayDi : ""}
                  </p>
                </div>
                <div className="flex">
                  <p className="my-1.5 font-medium mr-1">Loại xe: </p>
                  <p className="my-1.5">{marker ? marker.carType : ""}</p>
                </div>
                <div className="flex">
                  <p className="my-1.5 font-medium mr-1">Số lượng: </p>
                  <p className="my-1.5">
                    {marker
                      ? marker.tripType === 1
                        ? "Hàng"
                        : marker.tripType === 2
                        ? "Bao xe 7 chổ"
                        : marker.tripType === 3
                        ? "Bao xe 4 chổ"
                        : marker.soKhach
                      : ""}
                  </p>
                </div>
                <div className="flex">
                  <p className="my-1.5 font-medium mr-1">Ghi Chú: </p>
                  <p className="my-1.5 text-red-500 mr-2">
                    {marker ? (marker.tienFlg === 0 ? "Đã Thanh Toán" : "") : ""}
                  </p>
                  <p className="my-1.5 mb-1">{marker ? marker.ghiChu : ""}</p>
                </div>
                <div
                  className={
                    moment()
                      .add(-90, "minutes")
                      .isBefore(
                        moment(marker ? marker.ngayDi + marker.khungGioName : "", "DD/MM/YYYYHH:mm")
                      ) || admin
                      ? "flex justify-center"
                      : "hidden flex justify-center"
                  }
                >
                  <Button
                    className="!w-20 !ml-2"
                    type="primary"
                    // icon={<SaveOutlined />}
                    onClick={() => {
                      onEditTripMapPopup(marker);
                    }}
                    danger={gheFlg}
                  >
                    Sửa
                  </Button>
                  <Button
                    className="!w-20 !ml-2"
                    type="primary"
                    // icon={<SaveOutlined />}
                    onClick={() => {
                      onCancelTrip(marker);
                    }}
                    danger
                  >
                    Hủy
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-20 absolute top-0 left-0 flex">
          <Button
            type="primary"
            htmlType="submit"
            hidden={!selectMarker}
            onClick={clickTaoChuyen}
            disabled={Object.keys("recordButton").length > 0 || "isNew" == true ? false : true}
          >
            Tạo chuyến
          </Button>
          <Button
            danger
            type="primary"
            htmlType="submit"
            hidden={!selectMarker}
            onClick={clickThayDoiChuyen}
            disabled={Object.keys("recordButton").length > 0 || "isNew" == true ? false : true}
          >
            Thay đổi chuyến
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            hidden={!selectMarker}
            onClick={clickBoChon}
            disabled={Object.keys("recordButton").length > 0 || "isNew" == true ? false : true}
          >
            Bỏ chọn
          </Button>
        </div>
        <div
          className={
            !selectMarker ? "" : "" + "w-54 p-2 px-4 bg-red-500 absolute bottom-0 left-0 flex"
          }
        >
          <p className="text-base text-white mb-0">{textSelectMarker}</p>
        </div>
      </div>
      <ThayDoiTaiXePopup
        show={showPopup}
        popupId={popupId}
        data={dataPopup}
        driverList={driverList}
        khungGioList={khungGioList}
        onclose={handleClosePopup}
        onOk={handleOkPopup}
      ></ThayDoiTaiXePopup>
      <TripHistoryPopup
        data={historyPopupData}
        isOpen={isOpenHistory}
        onClose={onCloseHistory}
        cusId={cusId}
      ></TripHistoryPopup>
      <TripMapPopup
        data={tripMapPopupData}
        isOpen={isOpenTripMapPopup}
        onClose={onCloseTripMapPopup}
      ></TripMapPopup>
      <CallHistoryPopup
        data={callHistoryPopupData}
        isOpen={isOpenCallHistoryPopup}
        onClose={onCloseCallHistoryPopup}
      ></CallHistoryPopup>
    </Col>
  );
}

export default MapDetail;
