import { useState, useEffect } from "react";
import { PhoneOutlined, FileSearchOutlined, SoundOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip, Spin } from "antd";
import callInIcon from "../../assets/icon/call_in.svg";
import callOutIcon from "../../assets/icon/call_out.svg";
import callOutMissIcon from "../../assets/icon/call_out_miss.svg";
import callMissedIcon from "../../assets/icon/call_missed.svg";
import callInMiss from "../../assets/icon/call_in_miss.svg";
import moment from "moment";
import TripHistoryPopup from "pages/TripHistoryPopup/TripHistoryPopup";
import "moment/locale/vi";
import ReactAudioPlayer from "react-audio-player";
import { useSelector } from "react-redux";

moment.locale("vi");

function CallTable({ dataTable, loading, scroll }) {
  const [historyPopupData, setHistoryPopupData] = useState({});
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [cusId, setCusId] = useState("");
  const [dataSource, setDataSource] = useState([]);

  const { connectStatus } = useSelector((state) => state.calling);

  useEffect(() => {
    if (dataTable) {
      setDataSource(dataTable.slice(0, 10));
    }
  }, [dataTable]);

  const callPhoneNumber = (param) => {
    window.omiSDK.makeCall(param);
  };

  const onCloseHistory = () => {
    setIsOpenHistory(false);
  };

  const getCallStatusCell = (type, status) => {
    if (type === "outbound") {
      if (status == "ANSWERED") {
        return (
          <div className="flex items-center">
            <img src={callOutIcon} className="h-4 w-4 mr-2" />
            <div className="flex flex-col">
              <a className="text-black text-sm text-left">Gọi đi</a>
              <a className="text-slate-500 text-sm text-left">Trả lời</a>
            </div>
          </div>
        );
      } else {
        return (
          <div className="flex items-center">
            <img src={callOutMissIcon} className="h-4 w-4 mr-2" />
            <div className="flex flex-col">
              <a className="text-black text-sm text-left">Gọi đi</a>
              <a className="text-slate-500 text-sm text-left">Ko trả lời</a>
            </div>
          </div>
        );
      }
    } else if (type === "inbound") {
      if (status == "ANSWERED") {
        return (
          <div className="flex items-center">
            <img src={callInIcon} className="h-4 w-4 mr-2" />
            <div className="flex flex-col">
              <a className="text-black text-sm text-left">Gọi đến</a>
              <a className="text-slate-500 text-sm text-left">Trả lời</a>
            </div>
          </div>
        );
      } else if (status == "MISSED") {
        return (
          <div className="flex items-center">
            <img src={callMissedIcon} className="h-4 w-4 mr-2" />
            <div className="flex flex-col">
              <a className="text-black text-sm text-left">Gọi đến</a>
              <a className="text-slate-500 text-sm text-left">Trả lời</a>
            </div>
          </div>
        );
      } else {
        return (
          <div className="flex items-center">
            <img src={callInMiss} className="h-4 w-4 mr-2" />
            <div className="flex flex-col">
              <a className="text-black text-sm text-left">Gọi đên</a>
              <a className="text-slate-500 text-sm text-left">Ko trả lời</a>
            </div>
          </div>
        );
      }
    }
  };

  const getCallStatusCellBigSize = (type, status) => {
    if (type === "outbound") {
      if (status == "ANSWERED") {
        return (
          <div className="flex items-center">
            <img src={callOutIcon} className="h-6 w-6 mr-2" />
            <div className="flex flex-col">
              <a className="text-black text-base text-left">Gọi đi</a>
              <a className="text-slate-500 text-base text-left">Trả lời</a>
            </div>
          </div>
        );
      } else {
        return (
          <div className="flex items-center">
            <img src={callMissedIcon} className="h-6 w-6 mr-2" />
            <div className="flex flex-col">
              <a className="text-black text-base text-left">Gọi đi</a>
              <a className="text-slate-500 text-base text-left">Ko trả lời</a>
            </div>
          </div>
        );
      }
    } else if (type === "inbound") {
      if (status == "ANSWERED") {
        return (
          <div className="flex items-center">
            <img src={callInIcon} className="h-6 w-6 mr-2" />
            <div className="flex flex-col">
              <a className="text-black text-sm text-left">Gọi đến</a>
              <a className="text-slate-500 text-sm text-left">Trả lời</a>
            </div>
          </div>
        );
      } else {
        return (
          <div className="flex items-center">
            <img src={callMissedIcon} className="h-6 w-6 mr-2" />
            <div className="flex flex-col">
              <a className="text-black text-sm text-left">Gọi đên</a>
              <a className="text-slate-500 text-sm text-left">Ko trả lời</a>
            </div>
          </div>
        );
      }
    }
  };

  const defineThaoTac = (record) => {
    return (
      <div className="flex justify-center">
        <Tooltip title="Gọi" color={"#ffae42"}>
          <Button
            icon={<PhoneOutlined style={{ color: "#ffae42" }} />}
            onClick={() => {
              callPhoneNumber(record.callNumber);
            }}
            size="small"
            type="text"
            className="!ml-3 !rounded-md"
          />
        </Tooltip>
        <Tooltip title="Lịch sử đặt vé" color={"#47dded"}>
          <Button
            icon={<FileSearchOutlined style={{ color: "#47dded" }} />}
            onClick={() => openTripHistory(record)}
            size="small"
            type="text"
            className="!ml-3 !rounded-md"
          />
        </Tooltip>
        <Tooltip title="Tìm ghi âm" color={"#2986cc"}>
          <Button
            icon={<SoundOutlined style={{ color: "#2986cc" }} />}
            // onClick={onCallHistory}
            size="small"
            type="text"
            className="!ml-3 !rounded-md"
          />
        </Tooltip>
      </div>
    );
  };
  const defineThaoTacBig = (record) => {
    return (
      <div className="flex justify-center">
        <Tooltip title="Gọi" color={"#ffae42"}>
          <Button
            icon={<PhoneOutlined style={{ fontSize: "20px", color: "#ffae42" }} />}
            onClick={() => {
              callPhoneNumber(record.callNumber);
            }}
            size="small"
            type="text"
            className="!rounded-md"
            disabled={!connectStatus}
          />
        </Tooltip>
        <Tooltip title="Lịch sử đặt vé" color={"#47dded"}>
          <Button
            icon={<FileSearchOutlined style={{ fontSize: "20px", color: "#47dded" }} />}
            onClick={() => openTripHistory(record)}
            size="small"
            type="text"
            className="!ml-5 !rounded-md"
          />
        </Tooltip>
        <Tooltip title="Tìm ghi âm" color={"#2986cc"}>
          <Button
            icon={<SoundOutlined style={{ fontSize: "20px", color: "#2986cc" }} />}
            // onClick={onCallHistory}
            size="small"
            type="text"
            className="!ml-5 !rounded-md"
            disabled={!connectStatus}
          />
        </Tooltip>
      </div>
    );
  };

  const openTripHistory = (param) => {
    setCusId(param.callNumber);
    setHistoryPopupData({ cusId: param.callNumber });
    setIsOpenHistory(true);
  };

  const TableHistoryColumn = [
    {
      title: "Cuộc gọi",
      dataIndex: "callStatus",
      align: "center",
      width: "100px",
    },
    {
      title: "Vào lúc",
      dataIndex: "time",
      align: "center",
      width: "70px",
      render: (_, record) => (
        <div>
          <p className="!m-0">
            {moment(record.callDate, "YYYY-MM-DD hh:mm:ss").format("HH:mm ddd")}
          </p>
          <p className="!m-0 text-slate-500">
            {moment
              .utc(moment.duration(record.recordTime, "seconds").asMilliseconds())
              .format("mm:ss")}
          </p>
        </div>
      ),
    },
    {
      title: "SĐT Khách",
      dataIndex: "callNumber",
      align: "center",
      width: "100px",
      render: (_, record) => (
        <div>
          <p className="!m-0 text-left">{record.callName}</p>
          <p className="!m-0 text-left text-slate-500">{record.callNumber}</p>
        </div>
      ),
    },
    {
      title: "Đài",
      dataIndex: ["createUser", "callNumber"],
      width: "110px",
      render: (_, record) => (
        <div>
          <p className="!m-0 text-left text-xs">
            {record.line == 100
              ? "Thanh Hoa"
              : record.line == 101
              ? "Kim Tuyến"
              : record.line == 102
              ? "Ngọc Vỹ"
              : record.line == 103
              ? "Ngọc Anh"
              : record.line == 104
              ? "Văn Hải"
              : "Không ai trực"}
          </p>
          <p className="!m-0 text-left text-slate-500 !text-xs">{record.did}</p>
        </div>
      ),
    },
    {
      title: "Ghi âm",
      dataIndex: "recordFile",
      align: "center",
      width: "200px",
    },
    {
      title: "Thao tác",
      align: "center",
      // width: "60px",
      dataIndex: "operation",
      render: (_, record) => (scroll > 300 ? defineThaoTacBig(record) : defineThaoTac(record)),
    },
  ];

  function transformColumns(columns) {
    return columns.map((column) => {
      if (column.dataIndex === "callStatus") {
        return {
          ...column,
          render: (_, record) =>
            scroll > 300
              ? getCallStatusCellBigSize(record.callType, record.callStatus)
              : getCallStatusCell(record.callType, record.callStatus),
        };
      } else if (column.dataIndex === "recordFile") {
        return {
          ...column,
          render: (_, record) =>
            record.callStatus == "ANSWERED" ? (
              <ReactAudioPlayer
                className="block h-8 w-full max-w-md mx-auto"
                src={record.recordFile}
                // preload="none"
                controls
              />
            ) : (
              ""
            ),
        };
      } else {
        return column;
      }
    });
  }

  return (
    <Spin spinning={loading}>
      <Table
        className="table-call-popup"
        rowKey={(_, record) =>
          record.callHistoryId + Math.floor(Math.random() * 1000000).toString()
        }
        pagination={false}
        columns={transformColumns(TableHistoryColumn)}
        dataSource={dataSource}
        scroll={{ y: 300 }}
        // pagination={true}
        size="small"
      />
      <TripHistoryPopup
        data={historyPopupData}
        isOpen={isOpenHistory}
        onClose={onCloseHistory}
        cusId={cusId}
      ></TripHistoryPopup>
    </Spin>
  );
}

export default CallTable;
