import { useJsApiLoader } from "@react-google-maps/api";
import Geocode from "react-geocode";
import React, { useEffect, useState, useCallback } from "react";
import Point from "ol/geom/Point.js";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Vector as VectorSource } from "ol/source.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import Overlay from "ol/Overlay";
import { transform } from "ol/proj.js";
import OSM from "ol/source/OSM";
import Feature from "ol/Feature.js";
import { Icon, Style } from "ol/style";
import "moment/locale/vi";
import Fill from "ol/style/Fill";
import Text from "ol/style/Text";
import { defaults as defaultInteractions, Modify } from "ol/interaction";
import { fromLonLat } from "ol/proj";
import debounce from "lodash/debounce";

import m01 from "./01-260707.png";
import m02 from "./02-da1313.png";
import m03 from "./03-1b2dd3.png";
import m04 from "./04-0de053.png";
import m05 from "./20-fd7100.png";
import m06 from "./21-567328.png";
import m07 from "./22-5cb5b6.png";
import m08 from "./23-6880e8.png";
import m09 from "./24-f16787.png";
import m00 from "./05-d9e00d.png";
import m10 from "./10-e71b85.png";
import m11 from "./11-e71b85.png";
import { Form, AutoComplete } from "antd";
import { GOOGLE_MAP_API } from "core/Constants";

const libraries = ["places"];

function MapPopupDetail({ setTripInforFunc, tripOrder, tuyenId, driverList, dataCus, isOpen }) {
  Geocode.setApiKey(GOOGLE_MAP_API.GEOCODE);
  Geocode.enableDebug();
  const [diemDonAdd, setDiemDonAdd] = useState("");
  const [diemTraAdd, setDiemTraAdd] = useState("");
  const [diemDonLat, setDiemDonLat] = useState("");
  const [diemTraLat, setDiemTraLat] = useState("");
  const [diemDonLng, setDiemDonLng] = useState("");
  const [diemTraLng, setDiemTraLng] = useState("");

  const [tamKyAdd, setTamKyAdd] = useState("");
  const [daNangAdd, setDaNangAdd] = useState("");
  const [daNangSearchAdd, setDaNangSearchAdd] = useState("");

  const [markers, setMarkers] = useState([]);
  const [newMarker, setNewMarker] = useState();
  const [tuyenIdLocal, setTuyenIdLocal] = useState();
  const [map, setMap] = useState(null);
  const [vectorSource, setVectorSource] = useState(new VectorSource());
  const [centerLoc, setCenterLoc] = useState({
    lat: 16.047476382466968,
    lng: 108.19890504473366,
  });
  const [suggestionTK, setSuggestionTK] = useState([]);
  const [suggestionDN, setSuggestionDN] = useState([]);

  const [form] = Form.useForm();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API.MAP,
    libraries: libraries,
    language: "vi",
  });

  useEffect(() => {
    let listMarker = [];
    if ((!dataCus || tripOrder.length == 0) && !newMarker && dataCus.status != "COPY") {
      if (map && tripOrder.length == 0 && !newMarker) {
        const layersToRemove = map
          .getLayers()
          .getArray()
          .filter((layer) => layer instanceof VectorLayer);
        layersToRemove.forEach((layer) => map.removeLayer(layer));
      }
      return;
    }
    let markerCopy;
    if (dataCus.status === "EDIT") {
      listMarker = tripOrder.map((item) => {
        if (item.tuyen === "TKDN") {
          item.location = {
            lat: Number(item.diemTraLat),
            lng: Number(item.diemTraLng),
          };
        } else {
          item.location = {
            lat: Number(item.diemDonLat),
            lng: Number(item.diemDonLng),
          };
        }
        if (item.driverName) {
          driverList.map(function (o, index) {
            if (Number(o.driverId) === Number(item.driverId)) {
              if (index === 0) {
                item.icon = m01;
              } else if (index === 1) {
                item.icon = m02;
              } else if (index === 2) {
                item.icon = m03;
              } else if (index === 3) {
                item.icon = m04;
              } else if (index === 4) {
                item.icon = m05;
              } else if (index === 5) {
                item.icon = m06;
              } else if (index === 6) {
                item.icon = m07;
              } else if (index === 7) {
                item.icon = m08;
              } else if (index === 8) {
                item.icon = m09;
              }
            }
          });
        } else {
          item.icon = m11;
        }
        if (item.tripId === dataCus.tripId) {
          item.icon = m10;
          item.khungGioName = "VG";
          if (!diemDonAdd) {
            setDaNangAdd(dataCus.tuyen === "TKDN" ? dataCus.diemTraAdd : dataCus.diemDonAdd);
            setTamKyAdd(dataCus.tuyen === "DNTK" ? dataCus.diemTraAdd : dataCus.diemDonAdd);
            setDiemDonAdd(dataCus.diemDonAdd);
            setDiemDonLat(dataCus.diemDonLat);
            setDiemDonLng(dataCus.diemDonLng);
            setDiemTraAdd(dataCus.diemTraAdd);
            setDiemTraLat(dataCus.diemTraLat);
            setDiemTraLng(dataCus.diemTraLng);
          }
          item.newMarker = true;
        } else {
          item.newMarker = false;
          item.khungGioName = item.khungGioName.substring(0, 2) + "/" + item.soKhach;
        }
        return item;
      });
    } else if (dataCus.status === "COPY") {
      listMarker = tripOrder.map((item) => {
        if (item.tuyen === "TKDN") {
          item.location = {
            lat: Number(item.diemTraLat),
            lng: Number(item.diemTraLng),
          };
        } else {
          item.location = {
            lat: Number(item.diemDonLat),
            lng: Number(item.diemDonLng),
          };
        }
        if (item.driverName) {
          driverList.map(function (o, index) {
            if (Number(o.driverId) === Number(item.driverId)) {
              if (index === 0) {
                item.icon = m01;
              } else if (index === 1) {
                item.icon = m02;
              } else if (index === 2) {
                item.icon = m03;
              } else if (index === 3) {
                item.icon = m04;
              } else if (index === 4) {
                item.icon = m05;
              } else if (index === 5) {
                item.icon = m06;
              } else if (index === 6) {
                item.icon = m07;
              } else if (index === 7) {
                item.icon = m08;
              } else if (index === 8) {
                item.icon = m09;
              }
            }
          });
        } else {
          item.icon = m11;
        }
        item.khungGioName = item.khungGioName.substring(0, 2) + "/" + item.soKhach;

        return item;
      });
      if (!diemDonAdd) {
        setDaNangAdd(dataCus.tuyen === "TKDN" ? dataCus.diemTraAdd : dataCus.diemDonAdd);
        setTamKyAdd(dataCus.tuyen === "DNTK" ? dataCus.diemTraAdd : dataCus.diemDonAdd);
        setDiemDonAdd(dataCus.diemDonAdd);
        setDiemDonLat(dataCus.diemDonLat);
        setDiemDonLng(dataCus.diemDonLng);
        setDiemTraAdd(dataCus.diemTraAdd);
        setDiemTraLat(dataCus.diemTraLat);
        setDiemTraLng(dataCus.diemTraLng);
        markerCopy = {
          newMarker: true,
          name: dataCus.tuyen === "TKDN" ? dataCus.diemTraAdd : dataCus.diemDonAdd,
          status: "diemtra",
          location: {
            lat: dataCus.tuyen === "TKDN" ? dataCus.diemTraLat : dataCus.diemDonLat,
            lng: dataCus.tuyen === "TKDN" ? dataCus.diemTraLng : dataCus.diemDonLng,
          },
          khungGioName: "VG",
          icon: m10,
        };
        setNewMarker(markerCopy);
      }
    } else if (dataCus.status === "NEW") {
      listMarker = tripOrder.map((item) => {
        if (item.tuyen === "TKDN") {
          item.location = {
            lat: Number(item.diemTraLat),
            lng: Number(item.diemTraLng),
          };
        } else {
          item.location = {
            lat: Number(item.diemDonLat),
            lng: Number(item.diemDonLng),
          };
        }
        if (item.driverName) {
          driverList.map(function (o, index) {
            if (Number(o.driverId) === Number(item.driverId)) {
              if (index === 0) {
                item.icon = m01;
              } else if (index === 1) {
                item.icon = m02;
              } else if (index === 2) {
                item.icon = m03;
              } else if (index === 3) {
                item.icon = m04;
              } else if (index === 4) {
                item.icon = m05;
              } else if (index === 5) {
                item.icon = m06;
              } else if (index === 6) {
                item.icon = m07;
              } else if (index === 7) {
                item.icon = m08;
              } else if (index === 8) {
                item.icon = m09;
              }
            }
          });
        } else {
          item.icon = m11;
        }

        item.selected = false;
        item.newMarker = false;
        item.khungGioName = item.khungGioName.substring(0, 2) + "/" + item.soKhach;
        return item;
      });
    }
    if (newMarker) {
      setMarkers([...listMarker, newMarker]);
    } else {
      setMarkers(listMarker);
    }
    if (markerCopy) {
      setMarkers([...listMarker, markerCopy]);
    }
  }, [tripOrder]);

  useEffect(() => {
    if (map && markers.length > 0) {
      addMarkers(map, markers);
    }
  }, [markers, map]);

  const addMarkers = (map1, markers) => {
    const layersToRemove = map
      .getLayers()
      .getArray()
      .filter((layer) => layer instanceof VectorLayer);
    layersToRemove.forEach((layer) => map.removeLayer(layer));

    const vectorSource = new VectorSource({
      features: markers.map((marker) => {
        const feature = new Feature({
          geometry: new Point(fromLonLat([marker.location.lng, marker.location.lat])),
        });
        feature.setStyle(
          new Style({
            image: new Icon({
              anchor: [0.5, 1],
              src: marker.icon ? marker.icon : m11,
            }),
            text: new Text({
              text: marker ? marker.khungGioName : "",
              offsetY: -25,
              font: "8px Calibri,sans-serif",
              fontWeight: "bold",
              scale: 1.4,
              fill: new Fill({
                color: "#000000",
              }),
            }),
          })
        );
        return feature;
      }),
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    const modify = new Modify({ source: vectorSource });
    map1.addInteraction(modify);
    map1.addLayer(vectorLayer);
  };

  useEffect(() => {
    setTripInforFunc(diemDonAdd, diemDonLat, diemDonLng, diemTraAdd, diemTraLat, diemTraLng);
  }, [diemDonAdd, diemTraAdd, diemDonLat, diemDonLng, diemTraLng, diemTraLat]);

  // useEffect(() => {
  //   tuyenId == "DNTK" ? setDiemTraAdd(tamKyAdd) : setDiemDonAdd(tamKyAdd);
  // }, [tamKyAdd]);

  useEffect(() => {
    if (tuyenId) {
      if (tuyenId && tuyenIdLocal && tuyenId != tuyenIdLocal) {
        const diemDonAdd1 = diemDonAdd;
        const diemDonLat1 = diemDonLat;
        const diemDonLng1 = diemDonLng;
        setDiemDonAdd(diemTraAdd);
        setDiemDonLat(diemTraLat);
        setDiemDonLng(diemTraLng);
        setDiemTraAdd(diemDonAdd1);
        setDiemTraLat(diemDonLat1);
        setDiemTraLng(diemDonLng1);
      }
      if (tuyenId) {
        setTuyenIdLocal(tuyenId);
      }
      const osmLayer = new TileLayer({
        preload: Infinity,
        source: new OSM(),
      });

      const mapIns = new Map({
        target: "mapPopup",
        layers: [osmLayer],
        view: new View({
          center: transform([centerLoc.lng, centerLoc.lat], "EPSG:4326", "EPSG:3857"),
          zoom: 13,
        }),
        interactions: defaultInteractions({
          doubleClickZoom: false, // Disable double-click zoom
        }),
      });

      // vectorSource = new VectorSource();
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      mapIns.addLayer(vectorLayer);

      const container = document.getElementById("popup");

      const overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      mapIns.addOverlay(overlay);

      setMap(mapIns);
      return () => {
        mapIns.setTarget(null); // Clean up the map instance on unmount
      };
    }
  }, [tuyenId]);

  const onDNSelected = (value, option) => {
    const mapDiv = new window.google.maps.Map(document.createElement("div")); // Create a dummy map element
    const service = new window.google.maps.places.PlacesService(mapDiv);

    // Fetch place details by placeId
    service.getDetails({ placeId: option.placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        if (place.geometry && place.geometry.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          setDiemDonLat(lat);
          setDiemDonLng(lng);
          let marker = {
            newMarker: true,
            name: option.value,
            status: "diemtra",
            location: {
              lat: lat,
              lng: lng,
            },
            khungGioName: "VG",
            icon: m10,
          };
          const filterListMarker = markers.filter((obj) => {
            return !obj.newMarker;
          });
          setNewMarker(marker);
          const newMarkers = [...filterListMarker, marker];
          setMarkers(newMarkers);
          setDaNangAdd(option.value);
          if (tuyenId == "DNTK") {
            setDiemDonAdd(option.value);
            setDiemDonLat(lat);
            setDiemDonLng(lng);
          } else {
            setDiemTraAdd(option.value);
            setDiemTraLat(lat);
            setDiemTraLng(lng);
          }
        }
      } else {
        console.error("Error fetching place details:", status);
      }
    });
  };

  useEffect(() => {
    if (isLoaded && daNangSearchAdd.length > 0) {
      // eslint-disable-next-line no-undef
      const service = new window.google.maps.places.AutocompleteService();
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(15.15, 107.7), // SW corner (near Quảng Nam)
        new window.google.maps.LatLng(16.2, 108.5) // NE corner (Đà Nẵng)
      );

      const request = {
        input: daNangSearchAdd, // Example input query
        bounds: bounds, // Restrict to Đà Nẵng and Quảng Nam
        componentRestrictions: { country: "vn" }, // Restrict to Vietnam
      };
      try {
        service.getQueryPredictions(request, (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const options = predictions.map((item) => ({
              value: item.description,
              placeId: item.place_id,
            }));
            setSuggestionDN(options);
            return;
          }
          // Handle predictions
        });
      } catch (error) {
        console.error("Error in getQueryPredictions:", error);
      }
    }
  }, [daNangSearchAdd, isLoaded]);

  const onTKSelected = (value, place) => {
    setTamKyAdd(value);
    if (tuyenId == "TKDN") {
      setDiemDonAdd(value);
    } else {
      setDiemTraAdd(value);
    }
    form.setFieldsValue({
      diemDonForm: value,
    });
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchText) => {
      fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          searchText + " Quang Nam"
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          const options = data.map((item) => ({
            key: Math.floor(Math.random() * 1000000).toString(),
            value: item.display_name,
            lat: item.lat,
            lng: item.lon,
          }));
          setSuggestionTK(options);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    }, 800), // 2 seconds delay
    []
  );
  // Debounced search function
  const debouncedSearchDN = useCallback(
    debounce((searchText) => {
      setDaNangSearchAdd(searchText);
    }, 1500), // 2 seconds delay
    []
  );

  const searchBoxTK = () => {
    return (
      <AutoComplete
        style={{
          width: "100%",
        }}
        options={suggestionTK}
        onSelect={onTKSelected}
        className="w-full !h-10"
        value={tamKyAdd}
      >
        <input
          type="text"
          placeholder="Nhập địa điểm Tam Kỳ"
          className="w-full !h-10 text-base p-4 !border-2 !rounded-md !border-slate-300"
          value={tamKyAdd}
          onChange={(e) => {
            setTamKyAdd(e.target.value);
            if (tuyenId == "TKDN") {
              setDiemDonAdd(e.target.value);
            } else {
              setDiemTraAdd(e.target.value);
            }
            debouncedSearch(e.target.value);
          }}
        />
      </AutoComplete>
    );
  };

  const searchBoxDN = () => {
    return (
      <AutoComplete
        style={{
          width: "100%",
        }}
        options={suggestionDN}
        onSelect={onDNSelected}
        className="w-full !h-10"
        value={daNangAdd}
      >
        <input
          type="text"
          value={daNangAdd}
          onChange={(e) => {
            setDaNangAdd(e.target.value);
            debouncedSearchDN(e.target.value);
            // debounce(() => {
            //   setDaNangSearchAdd(e.target.value);
            // }, 500);
          }}
          className="w-full h-10 text-base p-4 border-2 rounded-md border-slate-300"
          placeholder="Nhập địa điểm Đà Nẵng"
        />
      </AutoComplete>
    );
  };

  return (
    isLoaded &&
    isOpen && (
      <div className="right-box-map">
        <Form colon={false} form={form}>
          <div className="mb-4 grid grid-cols-2">
            <div className="mr-4">
              <div className="flex">
                <p className="text-base font-bold">Điểm đón</p>
                <p className="text-base font-bold ml-2 text-red-500">
                  {tuyenId == "DNTK" ? "Đà Nẵng" : "Tam Kỳ"}
                </p>
              </div>

              {tuyenId == "DNTK" ? searchBoxDN() : searchBoxTK()}
            </div>
            <div className="mr-4">
              <div className="flex">
                <p className="text-base font-bold">Điểm trả</p>
                <p className="text-base font-bold ml-2 text-red-500">
                  {tuyenId == "DNTK" ? "Tam Kỳ" : "Đà Nẵng"}
                </p>
              </div>
              {tuyenId == "TKDN" ? searchBoxDN() : searchBoxTK()}
            </div>
          </div>
        </Form>
        <div className="h-full">
          <div style={{ height: "100%", width: "100%" }} id="mapPopup" className="map-container" />
        </div>
      </div>
    )
  );
}

export default MapPopupDetail;
