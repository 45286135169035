import { Modal, Button, Table, Form, message, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SelectCustom } from "components";
import tripRecordApi from "api/Call/tripRecord";

function ThayDoiTaiXePopup({ show, popupId, data, driverList, khungGioList, onclose, onOk }) {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [key, setKey] = useState([]);
  const [driverCombo, setDriverCombo] = useState([]);
  const [khungGioCombo, setKhungGioCombo] = useState([]);

  const [titleLeft, setTitleLeft] = useState("");
  const [titleRight, setTitleRight] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    show && setOpen(show);
  }, [show]);

  useEffect(() => {
    if (driverList) {
      const dataParse = [];
      driverList.map((item) =>
        dataParse.push({
          label:
            item["driverName"] + " - " + item["carNumber"] + " - Xe " + item["carType"] + " chổ",
          value: item["driverId"],
        })
      );
      setDriverCombo(dataParse);
    }
  }, [driverList]);

  const parseKhungGio = () => {
    if (khungGioList) {
      const dataParse = [];
      khungGioList.map((item) =>
        dataParse.push({
          label: item["khungGioName"],
          value: item["khungGioId"],
        })
      );
      setKhungGioCombo(dataParse);
    }
  };

  useEffect(() => {
    if (show) {
      parseKhungGio();
      form.setFieldsValue({
        driverId: data.driverId,
        khungGioId: data.khungGioId,
      });
      // getTripOrders(data);
    }
  }, [show]);

  const handleClose = () => {
    onclose();
    setOpen(false);
  };

  const onSave = async () => {
    const param = form.getFieldValue();
    const ls = driverList.find((d) => d.driverId === param.driverId);
    await tripRecordApi.updateDriverOrder({ ...ls, lsOrder: data });
    setOpen(false);
    onOk();
  };

  const onCopy = () => {
    let text = "";
    data.map(
      (item, index) =>
        (text =
          text +
          "\n" +
          `${index + 1}` +
          ". " +
          item.name +
          " - " +
          item.cusId +
          " - SL " +
          item.soKhach +
          " - " +
          `${item.diemDonAdd ? item.diemDonAdd.split(",")[0].trim() : ""}` +
          " - " +
          `${item.diemTraAdd ? item.diemTraAdd.split(",")[0].trim() : ""}` +
          " - " +
          (item.tripType === 1
            ? `${item.soTien}K `
            : item.tripType === 2
            ? `${item.soTien}K `
            : item.tripType === 3
            ? `${item.soTien}K `
            : `${item.soTien * item.soKhach}K `) +
          `${item.tienFlg === 0 ? " - Đã thanh toán " : ""}` +
          `${item.ghiChu ? item.ghiChu : ""}`)
    );

    navigator.clipboard.writeText(text);
    messageApi.open({
      type: "success",
      content: "Sao chép thành công",
    });

    // setOpen(false);
  };

  const configColumn = [
    {
      title: "No.",
      dataIndex: "index",
      align: "center",
      width: "60px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "name",
      align: "left",
      width: "150px",
    },
    {
      title: "SL",
      dataIndex: "soKhach",
      align: "center",
      width: "100px",
      render: (text, row) =>
        row["tripType"] === 1
          ? "Hàng"
          : row["tripType"] === 2
          ? "Bao xe 7 chổ"
          : row["tripType"] === 3
          ? "Bao xe 4 chổ"
          : row["soKhach"],
    },
    {
      title: "Điểm đón",
      dataIndex: "diemDonAdd",
      align: "left",
      width: "350px",
    },
    {
      title: "Điểm trả",
      dataIndex: "diemTraAdd",
      align: "left",
      width: "350px",
    },
    {
      title: "Giá",
      dataIndex: "soTien",
      align: "center",
      width: "80px",
      render: (text, row) => <a>{row["soTien"]}K</a>,
    },
    {
      title: "Ghi chú",
      dataIndex: "ghiChu",
      align: "left",
      render: (_, record) => (
        <div className="w-full h-full pl-2 p-1">
          <p className="text-red-500 mb-1 ">{record.tienFlg == 0 ? "Đã Thanh Toán" : ""}</p>
          <p className="mb-1">{record.ghiChu}</p>
        </div>
      ),
    },
  ];

  function transformColumns(columns) {
    return columns.map((column) => {
      if (column.dataIndex === "name") {
        return {
          ...column,
          render: (_, record) => (
            <div className="bg-blue-500 w-full h-full pl-2 p-1">
              <p className="text-white mb-1">{record.name}</p>
              <p className="text-white mb-1">{record.cusId}</p>
            </div>
          ),
        };
      } else {
        return column;
      }
    });
  }

  const onFinish = () => {
    // khungGioApi.saveKhungGio(form.getFieldValue());
  };

  return (
    <Modal
      destroyOnClose={true}
      onCancel={handleClose}
      // title={`Chuyến đi ngày  ${data.date}`}
      title={
        <div className="flex justify-between">
          {popupId === 0 ? "Chuyến đi ngày " + data[0].ngayDi : "Thay đổi lái xe"}
          <div className="flex">
            <Button key="copy" type="primary" onClick={onCopy}>
              Sao chép
            </Button>
            <Button
              key="add"
              type="primary"
              className="!bg-orange-500 !border-orange-500 w-24"
              onClick={onSave}
            >
              Lưu
            </Button>
            <Button key="close" type="primary" danger className=" w-24" onClick={handleClose}>
              Đóng
            </Button>
          </div>
        </div>
      }
      style={{ top: 20 }}
      open={open}
      closable={false}
      footer={null}
      width={"65%"}
    >
      {contextHolder}
      <div>
        <Form colon={false} onFinish={onFinish} form={form} className="form-control-inventory-his">
          <div className="mb-4">
            <Form.Item name="driverId">
              <SelectCustom
                label="Lái xe"
                require="true"
                options={driverCombo}
                showSearch
                allowClear
              ></SelectCustom>
            </Form.Item>
          </div>
          <div>
            <Form.Item name="khungGioId" hidden={popupId === 1}>
              <SelectCustom
                label="Giờ đi"
                require="true"
                options={khungGioCombo}
                showSearch
                allowClear
              ></SelectCustom>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="flex justify-between p-4">
        <div className="text-lg">{titleLeft}</div>
        <div className="text-lg">{titleRight}</div>
      </div>
      <div>
        <Table
          dataSource={data}
          columns={transformColumns(configColumn)}
          rowKey={() => Math.floor(Math.random() * 1000000).toString()}
          pagination={false}
          size="small"
          scroll={{
            y: 560,
          }}
        ></Table>
      </div>
    </Modal>
  );
}

export default ThayDoiTaiXePopup;
