import { useState, useEffect } from "react";
import { PhoneFilled } from "@ant-design/icons";
import { Button, Form } from "antd";
import { useSelector } from "react-redux";
import { InputCustom } from "components";
import moment from "moment";
import callApi from "api/Call/callApi";
import CallTablePopup from "./CallTablePopup";
import "./CallPopup.scss";
import "moment/locale/vi";

moment.locale("vi");

function CallPopup() {
  const [form] = Form.useForm();
  const { hangup, callPopup, connectStatus } = useSelector((state) => state.calling);
  const [lsCallHistory, setLsCallHistory] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (callPopup) {
      getCallHistory();
    }
  }, [callPopup]);

  const getCallHistory = async () => {
    try {
      setLoading(true);
      const result = await callApi.getCallHistory({});
      if (result.success) {
        setLoading(false);
        setLsCallHistory(result.data);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const callPhoneNumber = () => {
    window.omiSDK.makeCall(form.getFieldValue("phoneNumber"));
  };

  return (
    <div
      className={(!callPopup ? "hidden " : "") + "pb-2 shadow-xl rounded-md bg-white !h-82 w-145"}
    >
      {/* {!calling && ( */}
      <div>
        <div className="py-2 px-2 text-white font-bold inline-flex items-center rounded-t-md bg-gradient-to-r from-blue-500 to-cyan-500 w-full">
          Cuộc gọi đi
        </div>

        <div className="px-2 pb-2 ">
          <CallTablePopup dataTable={lsCallHistory} loading={loading} scroll={200} />
          {/* <Stopwatch show={acceptCall} run={acceptCall} /> */}
        </div>
        <div className="!bg-white">
          <Form colon={false} onFinish={callPhoneNumber} form={form} className="">
            <div className="flex justify-center mt-4 mb-1">
              <div className="mt-1">
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập sđt",
                    },
                  ]}
                >
                  <InputCustom placeholder="Số điện thoại"></InputCustom>
                </Form.Item>
              </div>
              <div className="mt-1 ml-2">
                {/* <Button type="primary" shape="circle" htmlType="submit" icon={<PhoneOutlined />} /> */}
                <Button
                  className="!bg-[#63d089]"
                  htmlType="submit"
                  shape="circle"
                  icon={<PhoneFilled style={{ fontSize: "20px", color: "#ffffff" }} />}
                  size="large"
                  disabled={!connectStatus}
                />
                {/* <Button shape="circle" icon={<SearchOutlined />} /> */}
              </div>
            </div>
          </Form>
        </div>
      </div>
      {/* )} */}
      {/* {calling && (
        <div className="flex justify-center py-4">
          <div className="h-12">
            <p className="m-0 text-xl text-[#294c82]">{phoneNumber}</p>
            <Stopwatch show={calling} run={acceptCall} />
          </div>

          <Button
            onClick={() => {
              endCall();
            }}
            shape="circle"
            className="!bg-[#dc4848] !ml-4 !h-12 !w-12"
            icon={<PhoneFilled rotate={225} style={{ fontSize: "20px", color: "#ffffff" }} />}
            size="large"
          />
        </div>
      )} */}
    </div>
  );
}

export default CallPopup;
