import { Table, Button, Tooltip, Modal } from "antd";
import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  CopyOutlined,
  CloseOutlined,
  UserAddOutlined,
  PhoneFilled,
  FileSearchOutlined,
} from "@ant-design/icons";
import TripMapPopup from "pages/TripMapPopup/TripMapPopup";
import ThayDoiTaiXePopup from "pages/TripMap/Components/popup/ThayDoiTaiXePopup";
import TripEditHisPopup from "pages/TripEditHisPopup/TripEditHisPopup";
import moment from "moment";
import tripRecordApi from "api/Call/tripRecord";
import { useDispatch } from "react-redux";
import tripPlanApi from "api/Call/tripPlanApi";
import "moment/locale/vi";
import TokenService from "utils/TokenService";

const admin = TokenService.getUserLocalStorage()?.coCd == "CLV";

moment.locale("vi");
const { confirm } = Modal;

function TripRecordListTable({ dataTable, loading }) {
  const dispatch = useDispatch();
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [dataPopup, setDataPopup] = useState({});
  const [showTaiXe, setShowTaiXe] = useState(false);
  const [dataThayDoi, setDataThayDoi] = useState([]);
  const [driverList, setDriverList] = useState([]);

  const [dataEditHis, setDataEditHis] = useState({});
  const [showEditHis, setShowEditHis] = useState(false);

  useEffect(() => {
    const resizeHandler = () => {
      const windowHeight = window.innerHeight;
      const tableHeight = windowHeight - 170; // You can adjust this value according to your needs
      const tableElement = document.querySelector(".ant-table-body");
      if (tableElement) {
        tableElement.style.maxHeight = `${tableHeight}px`;
      }
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const callPhoneNumber = (param) => {
    window.omiSDK.makeCall(param);
  };

  function transformColumns(columns) {
    return columns.map((column) => {
      if (column.dataIndex === "name") {
        return {
          ...column,
          render: (_, record) => (
            <div
              className={
                record.tripStatus == "Y"
                  ? "bg-blue-500 w-full h-full pl-2 p-1"
                  : "bg-red-500 w-full h-full pl-2 p-1"
              }
            >
              <p className="text-white mb-0.5">{record.name}</p>
              <p className="text-white mb-0">{record.cusId}</p>
              {/* when hover */}
              <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center opacity-0 hover:opacity-100">
                <div className="flex justify-end w-full h-full">
                  <Tooltip title="Gọi" color={"#ffae42"} key={"ffae42"}>
                    <Button
                      icon={<PhoneFilled style={{ color: "#ffae42" }} />}
                      onClick={() => {
                        callPhoneNumber(record.cusId);
                      }}
                      size="small"
                      className="!ml-2 !rounded-md"
                    />
                  </Tooltip>
                  <Tooltip title="Lịch sử chỉnh sửa" color={"#47dded"} key={"47dded"}>
                    <Button
                      icon={<FileSearchOutlined style={{ color: "#47dded" }} />}
                      onClick={() => onEditHis(record)}
                      // disabled={true}
                      size="small"
                      className="!ml-2 !rounded-md"
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          ),
        };
      } else if (column.dataIndex === "ghiChu") {
        return {
          ...column,
          render: (_, record) => (
            <div className="w-full h-full pl-2 p-1">
              <p className="text-red-500 mb-1 ">{record.tienFlg === 0 ? "Đã Thanh Toán" : ""}</p>
              <p className="mb-1">{record.ghiChu}</p>
            </div>
          ),
        };
      }
      {
        return column;
      }
    });
  }

  const onClose = () => {
    setIsOpenDetail(false);
  };

  const onCloseTaxi = () => {
    setShowTaiXe(false);
  };

  const onCloseEditHis = () => {
    setShowEditHis(false);
  };

  const onEdit = (param) => {
    setDataPopup({ ...param, status: "EDIT" });
    setIsOpenDetail(true);
  };

  const onEditHis = (param) => {
    setDataEditHis(param);
    setShowEditHis(true);
  };

  const onChangeTaiXe = async (param) => {
    const resultDriverPlan = await tripPlanApi.getDriverPlan({ ngayPlan: param.ngayDi });
    const driverLs = resultDriverPlan.data.filter((item) => {
      return item.khungGioId == param.khungGioId;
    });
    setDataThayDoi([...[], param]);
    setDriverList(driverLs);
    setShowTaiXe(true);
  };

  const onCopy = (param) => {
    setDataPopup({ ...param, status: "COPY" });
    setIsOpenDetail(true);
  };

  const onCancelTrip = (param) => {
    confirm({
      title: "Bạn có muốn hủy chuyến đi này",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await tripRecordApi.updateOrderStatus(param);
      },
      onCancel() {
        return;
      },
    });
  };

  const defineInventoryHis = [
    {
      title: "No.",
      dataIndex: "index",
      align: "center",
      width: "60px",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "name",
      align: "left",
      width: "120px",
    },
    {
      title: "SL",
      dataIndex: "soKhach",
      align: "center",
      width: "60px",
      render: (text, row) =>
        row["tripType"] === 1
          ? "Hàng"
          : row["tripType"] === 2
          ? "Bao xe 7 chổ"
          : row["tripType"] === 3
          ? "Bao xe 4 chổ"
          : row["soKhach"],
    },
    {
      title: "Giờ",
      dataIndex: "khungGioName",
      align: "center",
      width: "60px",
    },
    {
      title: "Ngày",
      dataIndex: "ngayDi",
      align: "center",
      width: "80px",
      render: (text, row) => moment(moment(row["ngayDi"], "DD/MM/YYYY")).format("DD-MM (dddd)"),
    },
    {
      title: "Giá",
      dataIndex: "soTien",
      align: "center",
      width: "60px",
      render: (_, record) =>
        record.tripType === 1
          ? `${record.soTien}K`
          : record.tripType === 2
          ? `${record.soTien}K`
          : record.tripType === 3
          ? `${record.soTien}K`
          : `${record.soTien * record.soKhach}K`,
    },
    {
      title: "Xe",
      dataIndex: "carType",
      align: "center",
      width: "50px",
    },
    {
      title: "Tuyến",
      dataIndex: "tuyenName",
      align: "center",
      width: "80px",
    },
    {
      title: "Điểm đón",
      dataIndex: "diemDonAdd",
      align: "center",
      // width: "180px",
    },
    {
      title: "Điểm trả",
      dataIndex: "diemTraAdd",
      align: "center",
      // width: "180px",
    },
    {
      title: "Ghi chú",
      dataIndex: "ghiChu",
      width: "120px",
      align: "center",
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngayTao",
      align: "center",
      width: "100px",
    },
    {
      title: "Ng.Tạo",
      dataIndex: "createUser",
      width: "90px",
      align: "center",
    },
    {
      title: "Lái xe",
      dataIndex: "driverName",
      width: "80px",
      align: "center",
      render: (_, record) =>
        record.driverId ||
        moment()
          .add(-90, "minutes")
          .isAfter(moment(record.ngayDi + record.khungGioName, "DD/MM/YYYYHH:mm")) ? (
          record.driverName
        ) : (
          <div className="flex justify-center">
            <Tooltip title="Gán tài xế" color={"blue"} key={"blue"}>
              <Button
                icon={<UserAddOutlined style={{ fontSize: "20px", color: "blue" }} />}
                onClick={() => {
                  onChangeTaiXe(record);
                }}
                type="text"
                size="small"
              />
            </Tooltip>
          </div>
        ),
    },
    {
      title: "Thao Tác",
      dataIndex: "ttlQty",
      responsive: ["md"],
      width: "120px",
      inputType: "number",
      require: false,
      align: "center",
      render: (_, record) => (
        <div className="flex justify-between">
          <Tooltip title="Sao chép" color={"green"} key={"green1"}>
            <Button
              icon={<CopyOutlined style={{ fontSize: "20px", color: "#6aa84f" }} />}
              onClick={() => {
                onCopy(record);
              }}
              type="text"
              size="small"
            />
          </Tooltip>
          <Tooltip title="Chỉnh sửa" color={"purple"} key={"purple"}>
            <Button
              icon={
                <EditOutlined
                  style={
                    !moment()
                      .add(-90, "minutes")
                      .isAfter(moment(record.ngayDi + record.khungGioName, "DD/MM/YYYYHH:mm")) ||
                    admin
                      ? { fontSize: "20px", color: "purple" }
                      : { fontSize: "20px", color: "gray" }
                  }
                />
              }
              onClick={() => {
                onEdit(record);
              }}
              type="text"
              disabled={
                moment()
                  .add(-90, "minutes")
                  .isAfter(moment(record.ngayDi + record.khungGioName, "DD/MM/YYYYHH:mm")) && !admin
              }
              size="small"
            />
          </Tooltip>
          <Tooltip title="Hủy" color={"red"} key={"red"}>
            <Button
              icon={
                <CloseOutlined
                  style={
                    !moment()
                      .add(-90, "minutes")
                      .isAfter(moment(record.ngayDi + record.khungGioName, "DD/MM/YYYYHH:mm")) ||
                    admin
                      ? { fontSize: "20px", color: "red" }
                      : { fontSize: "20px", color: "gray" }
                  }
                />
              }
              onClick={() => {
                onCancelTrip(record);
              }}
              type="text"
              size="small"
              disabled={
                moment()
                  .add(-90, "minutes")
                  .isAfter(moment(record.ngayDi + record.khungGioName, "DD/MM/YYYYHH:mm")) && !admin
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="table-trip-record-his">
        <Table
          columns={transformColumns(defineInventoryHis)}
          dataSource={dataTable}
          rowKey={(record) => record.mtrlId + Math.floor(Math.random() * 1000000).toString()}
          bordered
          scroll={{ y: 900 }}
          size="small"
          loading={loading}
          pagination={{ pageSize: 30 }}
        />
      </div>
      <TripMapPopup data={dataPopup} isOpen={isOpenDetail} onClose={onClose}></TripMapPopup>
      <ThayDoiTaiXePopup
        show={showTaiXe}
        popupId={1}
        data={dataThayDoi}
        driverList={driverList}
        // khungGioList={khungGioList}
        onclose={onCloseTaxi}
        // onOk={handleOkThayDoi}
      ></ThayDoiTaiXePopup>
      <TripEditHisPopup
        isOpen={showEditHis}
        data={dataEditHis}
        onClose={onCloseEditHis}
      ></TripEditHisPopup>
    </>
  );
}

export default TripRecordListTable;
