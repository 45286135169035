import "App.scss";
import React, { useEffect, useCallback } from "react";
import { ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import moment from "moment";
import _ from "lodash";

import Route from "routes/route";
import { logoutBroadcast } from "core/Broadcast";
import { logout } from "redux/userSlide";
import { setCallData, acceptByAnother } from "redux/callingSlice";
import TokenService from "utils/TokenService";
import callApi from "api/Call/callApi";
import exApi from "api/Extenal/exApi";

ConfigProvider.config({
  theme: {
    primaryColor: "#4c78ae",
  },
});
let connect = true;

function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const { phoneNumber, connectStatus } = useSelector((state) => state.calling);
  const timers = new Map();

  useEffect(() => {
    connect = connectStatus;
  }, [connectStatus]);

  useEffect(() => {
    if (token) {
      const initOmiSDK = () => {
        let config = {
          theme: "default", // sử dụng UI mặc định của sdk
          debug: false, // cho phép console.log các event call và trạng thái trong sdk
          busy: false, // nếu true, sẽ auto reject các cuộc gọi đến, nhưng vẫn có thể thực hiện cuộc gọi ra
          language: "vi", // Ngôn ngữ giao diện dialog,
          ringtoneVolume: 1,
          options: {
            showNoteInput: false, // hiển thị input note mặc định để lưu vào Omi, sẽ submit qua callback "saveCallInfo" khi cuộc gọi đã kết thúc và dialog call được đóng
            hideCallButton: false, // ẩn hiển thị nút toggle dialog nhập số để gọi ra mặc định
            showContactLoading: false, // hiển thị loading ở dialog gọi khi có cuộc gọi đến, dùng kết hợp với function omiSDK.updateContactInfo để hiển thị avatar và tên của số điện thoại gọi đến
          },
          // ],w
          callbacks: {
            register: (data) => {
              // Sự kiện xảy ra khi trạng thái kết nối tổng đài thay đổi
              // console.log("register:", data);
            },
            connecting: (data) => {
              // // Sự kiện xảy ra khi bắt đầu thực hiện cuộc gọi ra
              handleConnecting(data);
            },
            invite: (data) => {
              // Sự kiện xảy ra khi có cuộc gọi tới
              handleInvite(data);
            },
            inviteRejected: (data) => {
              // Sự kiện xảy ra khi có cuộc gọi tới, nhưng bị tự động từ chối
              // trong khi đang diễn ra một cuộc gọi khác
              // console.log("inviteRejected:", data);
            },
            ringing: (data) => {
              // Sự kiện xảy ra khi cuộc gọi ra bắt đầu đổ chuông
              // console.log("ringing:", data);
            },
            accepted: (data) => {
              // Sự kiện xảy ra khi cuộc gọi vừa được chấp nhận
              handleAccepted(data);
            },
            incall: (data) => {
              // Sự kiện xảy ra mỗi 1 giây sau khi cuộc gọi đã được chấp nhận
              // console.log("incall:", data);
            },
            acceptedByOther: (data) => {
              // Sự kiện dùng để kiểm tra xem cuộc gọi bị kết thúc
              // đã được chấp nhận ở thiết bị khác hay không
              // console.log("acceptedByOther:", data);
            },
            ended: (data) => {
              // Sự kiện xảy ra khi cuộc gọi kết thúc
              handleEnded();
            },
            holdChanged: (status) => {
              // Sự kiện xảy ra khi trạng thái giữ cuộc gọi thay đổi
              // console.log("on hold:", status);
            },
            saveCallInfo: (data) => {
              // data = { callId, note, ...formData };
              // Sự kiện xảy ra khi cuộc gọi đã có đổ chuông hoặc cuộc gọi tới, khi user có nhập note input mặc định hoặc form input custom
              // console.log("on save call info:", data);
            },
          },
        };
        if (connect) {
          window.omiSDK.init(config, () => {
            let extension = {
              domain: "votrquan", // Replace with your domain
              username: TokenService.getUserLocalStorage()?.line, // Replace with your username
              password: TokenService.getUserLocalStorage()?.passCall, // Replace with your password
            };

            window.omiSDK.register(extension, (error) => {
              if (error) {
                console.log("Register error:", error);
              } else {
                console.log("Register finish!");
              }
            });
          });
        }
      };

      // Ensure omiSDK is loaded before calling initOmiSDK
      if (window.omiSDK) {
        initOmiSDK();
      } else {
        console.error("omiSDK is not loaded");
      }

      var sock = new SockJS("https://office.vugiataxi.com/ws");
      // var sock = new SockJS(process.env.REACT_APP_WS);
      let stompClient = Stomp.over(sock);
      stompClient.debug = null;
      sock.onopen = function () {
        // console.log("open");
      };

      stompClient.connect({}, function (frame) {
        stompClient.subscribe("/topic/reply", function (greeting) {
          const jsonObject = JSON.parse(greeting.body);
          if (
            jsonObject.direction == "inbound" &&
            TokenService.getUserLocalStorage()?.line != jsonObject.line &&
            TokenService.getUserLocalStorage()?.fromNumber != jsonObject.line &&
            !phoneNumber
          ) {
            if (jsonObject.state == "answered") {
              let param = {
                acceptCallByOther: true,
                phoneNumberOther: jsonObject.fromNumber,
                userAccept:
                  jsonObject.line == 100
                    ? "Thanh Hoa"
                    : jsonObject.line == 101
                    ? "Kim Tuyến"
                    : jsonObject.line == 102
                    ? "Ngọc Vỹ"
                    : jsonObject.line == 103
                    ? "Ngọc Anh"
                    : jsonObject.line == 104
                    ? "Văn Hải"
                    : "Thanh Hoa",
              };
              dispatch(acceptByAnother(param));
            } else {
              let param = {
                acceptCallByOther: false,
                phoneNumberOther: "",
                userAccept: "",
              };
              dispatch(acceptByAnother(param));
            }
          }
        });
      });
    }
  }, [token]);

  const handleConnecting = (data) => {
    const dataCall = {
      phoneNumber: data.phone,
      status: data.direction,
      hangup: false,
      acceptCall: false,
      callPopup: false,
    };
    dispatch(setCallData(dataCall));
    // const param = {
    //   callId: data.id,
    //   callDate: moment(data.startTime).format("YYYY-MM-DD HH:mm:ss"),
    //   callNumber: data.remoteNumber,
    //   callStatus: "NO ANSWER",
    //   callType: "outbound",
    //   line: data.line,
    //   recordTime: data.duration,
    //   did: data.sipNumber,
    // };
    // callApi.saveCallHistory(param);
  };

  const handleInvite = (data) => {
    const dataCall = {
      phoneNumber: data.remoteNumber,
      status: data.direction,
      hangup: false,
      acceptCall: false,
      callPopup: false,
    };
    dispatch(setCallData(dataCall));

    // const param = {
    //   callId: data.uuid,
    //   callDate: moment(data.startTime).format("YYYY-MM-DD HH:mm:ss"),
    //   callNumber: data.remoteNumber,
    //   callStatus: "NO ANSWER",
    //   callType: "inbound",
    //   line: data.line,
    //   did: data.sipNumber,
    // };
    // callApi.saveCallHistory(param);
  };

  const handleAccepted = (data) => {
    const dataCall = {
      phoneNumber: data.phone,
      status: data.direction,
      hangup: false,
      acceptCall: true,
      callPopup: false,
    };
    dispatch(setCallData(dataCall));
  };

  const handleEnded = () => {
    const dataCall = {
      phoneNumber: "",
      status: "nocall",
      hangup: true,
      acceptCall: false,
      callPopup: false,
    };
    dispatch(setCallData(dataCall));
  };

  const handleAcceptByOther = (jsonObject) => {
    let param = {
      acceptCallByOther: jsonObject.state === "answered",
      phoneNumberOther: jsonObject.fromNumber,
      userAccept: getUserAcceptName(jsonObject.line),
    };
    dispatch(acceptByAnother(param));
  };

  const getUserAcceptName = (line) => {
    const users = {
      100: "Thanh Hoa",
      101: "Kim Tuyến",
      102: "Ngọc Vỹ",
      103: "Ngọc Anh",
      104: "Văn Hải",
    };
    return users[line] || "Thanh Hoa";
  };

  useEffect(() => {
    logoutBroadcast.onmessage = () => {
      dispatch(logout());
      window.location.href = `${window.location.origin}/login`;
    };
  }, [dispatch]);

  return <Route />;
}

export default App;
